$(function () {
    // Добавляем в форму заказа текст из специального атрибута кнопки, вызывающей модальное окно
    $('[data-toggle="modal"]').click(function () {
        let message = $(this).data('message');

        if (message) {
            $($(this).data('target')).find(':input[name="comment"]').val(message);
        }
    });

    // Открытие услуги по параметру в адресе
    let $selectedService = $('input#selected-service');

    if ($selectedService.length) {
        setTimeout(function () {
            $('[data-isotope-filter="' + $selectedService.val() + '"]').click();
        }, 500);
    }
});

function hideActiveModal() {
    let $modal = $('.modal.show');

    if ($modal.length) {
        setTimeout(function () {
            $modal.modal('hide');
        }, 1000);
    }
}